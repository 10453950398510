<template>
  <div>
    <portal to="title-page">
      <h1>Transporte</h1>
    </portal>
    <v-data-table
      v-if="toursData != null"
      v-model="selected"
      :headers="tableHeaders"
      :items="toursData"
      :search="search"
      item-key="id"
      fixed-header
      class="elevation-1"
    >
      <template v-slot:top>
        <div class="table-top-bar">
          <v-row>
            <v-col cols="6" sm="6" md="6">
              <v-text-field
                v-model="search"
                prepend-icon="mdi-magnify"
                label="Buscar"
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="6" md="6">
              <v-btn
                @click="$router.push({path:'/backend/admin/transport/create'})"
                small
                color="primary"
              >
                <v-icon>mdi-plus-thick</v-icon>Transporte
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <v-divider></v-divider>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          class="mr-2"
          @click="$router.push({path:`/backend/admin/tours/${item.id}/transport/edit`})"
        >mdi-pencil</v-icon>
        
      </template>
    </v-data-table>
    <v-snackbar v-model="snackbar.show" :color="snackbar.color">
      {{snackbar.message}}
      <template v-slot:action="{ attrs }">
        <v-btn color text v-bind="attrs" @click="snackbar.show = false">
          <v-icon>{{mdiClose}}</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import Transport from "@/models/Transport";
import { mdiClose, mdiPencil, mdiPackageVariantClosed } from "@mdi/js";
import Tours from "@/models/Tour.js";

export default {
  name: "Transport",
  data: () => {
    return {
      mdiPencil: mdiPencil,
      mdiClose: mdiClose,
      mdiPackageVariantClosed: mdiPackageVariantClosed,
      snackbar: {
        show: false,
        message: "",
        color: "",
      },
      ToursModel: new Tours(),
      transportModel: new Transport(),
      transport: [],
      selected: [],
      toursData: [],
      tour: [],
      search: "",
      tableHeaders: [
        {
          text: "Tour",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "Max pax por vehiculo para servicio Privado",
          align: "start",
          sortable: true,
          value: "maxPaxPrivate",
        },
        {
          text: "Min pax para servicio Colectivo",
          align: "start",
          sortable: false,
          value: "minPaxShared",
        },
        { text: "Actions", value: "actions", sortable: false },
      ],
      changeTour: null,
    };
  },
  methods: {
    deleteZone(item) {
      console.log(item.id, "hola");
      this.$dialog
        .confirm(
          `¿Desea eliminar la tarifa del transporte del tour <strong>${this.tour.name}</strong>?`,
          {
            loader: true,
          }
        )
        .then((dialog) => {
          this.transportModel
            .delete(this.tour.id, item.id)
            .then(() => {
              dialog.close();
              this.snackbar.show = true;
              this.snackbar.color = "green";
              this.snackbar.message = `La tarifa del transporte se elimino correctamente`;
            })
            .catch((error) => {
              console.error("Error removing document: ", error);
              dialog.close();
              this.snackbar.show = true;
              this.snackbar.color = "red";
              this.snackbar.message = `Error al eliminar la tarifa del transporte, por favor contacte al Administrador si el problema persiste`;
            });
        });
    },
  },
  mounted() {
    this.transportModel.all().onSnapshot(snap => {
      this.toursData = [];
      snap.forEach(doc => {
        let stour = doc.data();
        if (stour.deleted == 0) {
          stour.id = doc.id;
          this.toursData.push(stour)
        }
      });
    });
  },
  created() {
    this.$store.commit("SET_LAYOUT", "admin");
  },
};
</script>
